exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentor-js": () => import("./../../../src/pages/mentor.js" /* webpackChunkName: "component---src-pages-mentor-js" */),
  "component---src-pages-side-projects-js": () => import("./../../../src/pages/side-projects.js" /* webpackChunkName: "component---src-pages-side-projects-js" */),
  "component---src-templates-articulos-template-js": () => import("./../../../src/templates/articulos-template.js" /* webpackChunkName: "component---src-templates-articulos-template-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-my-second-post-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/website/content/blog/my-second-post/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-my-second-post-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-new-beginnings-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/website/content/blog/new-beginnings/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-new-beginnings-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-primeros-dias-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/website/content/blog/Primeros dias/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-primeros-dias-index-mdx" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */)
}

